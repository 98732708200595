/** @jsxImportSource @emotion/react */
import { CampaignsTemplate } from "./components/CampaignsTemplate/CampaignsTemplate";
import { TargetLanguage } from "./components/TargetLanguage/TargetLanguage";
import { Platform } from "./components/Platform/Platform";
import { SearchCampaignContainer } from './components/SearchCampaignContainer/SearchCampaignContainer';
import { ObjectiveCampaign } from "./components/ObjectiveCampaign/ObjectiveCampaign";
import { TargetDevices } from "./components/TargetDevices/TargetDevices";
import { CampaignSetup } from "./components/CampaignSetup/CampaignSetup";
import { Gender } from "./components/Gender/Gender";
import { AgeRange } from "./components/AgeRange/AgeRange";
import { MoreOptions } from "./components/MoreOptions/MoreOptions";
import { BidAmount } from "./components/BidAmount/BidAmount";
import { DetailedTarget } from "./components/DetailedTarget/DetailedTarget";
import { TargetCountries } from "./components/TargetCountries/TargetCountries";
import { useRecoilValue } from "recoil";
import { isDuplicateState } from "../DuplicationTarget/components/DuplicationType/DuplicationType.state";



export const Target = () => {
  const isDuplicate = useRecoilValue(isDuplicateState);

  return (
    < >
        <CampaignsTemplate/>
        <TargetLanguage/>
        <Platform/>
        <SearchCampaignContainer showToggle={isDuplicate}/>
        <ObjectiveCampaign/>
        <TargetDevices/>
        <CampaignSetup/>
        <BidAmount/>
        <TargetCountries/>
        <Gender/>
        <AgeRange/>
        <MoreOptions/>
        <DetailedTarget/>
    </>
  );
}
