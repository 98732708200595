import { AdsOptimization, BidStrategy, Device, Objective, SearchCampaign, SearchProvider } from "../../createCampign.typs";
import { isValidIdentifier } from './components/Target/components/SearchCampaignContainer/searchCampaign.validations';
import { CampaignTarget } from "./components/Target/targetState/target.atom";

export const validateCampaign = (campaignTarget: CampaignTarget, isSearchCampaign: boolean) => {
  let errors: string[] = [];

  if (isSearchCampaign) {
    errors = validateSearchCampaign(campaignTarget.searchCampaign as SearchCampaign, campaignTarget.useOriginSearchValues);
  }

  const validateDevicesErrors = validateDevices(campaignTarget.devices);
  const validateAdsOptimizationErrors = validateAdsOptimization(isSearchCampaign, campaignTarget.adsOptimization, campaignTarget.bidStrategy, campaignTarget.bidAmount);
  const validateConversionEventsErrors = validateConversionEvents(campaignTarget.conversionsEvent, campaignTarget.objective);
  if (campaignTarget.countries.length === 0) {
    errors.push('Please select At least 1 Country');
  }

  errors = [
    ...errors,
    ...validateDevicesErrors,
    ...validateAdsOptimizationErrors,
    ...validateConversionEventsErrors,
  ];
  return errors;
}


const validateSearchCampaign = (searchCampaign: SearchCampaign, useOriginSearchValues?: boolean) => {
  let errors: string[] = [];
  if (!searchCampaign?.searchProvider) {
    errors.push('Please select search provider');
    return errors;
  }
  if (!useOriginSearchValues) {
    if (searchCampaign.searchProvider === SearchProvider.Tonic && !searchCampaign.tonicCampaign) {
      errors.push('Please select a tonic campaign');
      return errors;
    }

    if (searchCampaign.searchProvider === SearchProvider.ExploreAds && !searchCampaign.campaignOffer) {
      errors.push('Please insert campaign offer');
    }

    if ((searchCampaign.searchProvider === SearchProvider.ExploreAds || searchCampaign.searchProvider === SearchProvider.AFS) && !searchCampaign.identifier) {
      errors.push('Please insert identifier');
    }

    if ((searchCampaign.searchProvider === 'exploreAds' || searchCampaign.searchProvider === 'googleDirect') && !isValidIdentifier(searchCampaign.identifier)) {
      errors.push('Identifier field can\'t include spaces and underscores');
    }
  }
  return errors;
}

const validateDevices = (devices: { device: Device, browsersAndOS: string }[]) => {
  if (devices.length === 0) {
    return ['Please select At least 1 Device'];
  }

  const duplicateDevice = devices.some((item, index, arr) =>
    arr.filter((otherItem) => otherItem.device === item.device).length > 1
  );
  if (duplicateDevice) {
    return ['Duplicate Devices Selected'];
  }
  return [];
}

const validateAdsOptimization = (isSearchCampaign: boolean, adsOptimization?: string[], bidStrategy?: BidStrategy[], bidAmount?: number[]) => {
  if (adsOptimization?.length === 0) {
    return ['Please select At least 1 Optimization'];
  }
  if (!adsOptimization?.includes(AdsOptimization.Value)) {
    if (bidStrategy?.length === 0) {
      return ['Please select At least 1 bid strategy'];
    }
  } else if (!isSearchCampaign && bidAmount?.length === 0) {
    return ['Please select At least 1 bid amount'];
  }
  return [];
}

const validateConversionEvents = (conversionEvents: string[], campaignObjective: Objective) => {
  if (campaignObjective !== Objective.Traffic && conversionEvents.length === 0) {
    return ['Please select At least 1 conversionEvents'];
  }
  return [];
}
