import { atom } from "recoil";
import { AdsOptimization, BidStrategy, Device, FBInterest, GenderType, Objective, SearchCampaign, TrafficOptimization } from '../../../../../createCampign.typs';

export type CampaignTarget = {
  isLanguageSet: boolean;
  objective: Objective;
  moreOptions: object;
  searchCampaign?: SearchCampaign;
  bidAmount: number[];
  language: string;
  countries: { id: string, value: string }[];
  devices: { device: Device, browsersAndOS: string }[];
  adsOptimization: AdsOptimization[] | TrafficOptimization[];
  conversionsEvent: string[];
  bidStrategy?: BidStrategy[];
  customAudiences?: {
    website?: { id: string, name: string }[];
    lookalike?: { id: string, name: string }[];
  }
  customPixel?: string;
  gender?: GenderType | null;
  ages: string[];
  isDynamicCreative: boolean;
  interests?: FBInterest[];
  allInterestSearch?: FBInterest[];
  roas?: number[];
  uploadToInstagram?: boolean;
  conversionShift?: number;
  useOriginSearchValues?: boolean;
}

const initialTargetState = {
  isLanguageSet: true,
  objective: Objective.Sales,
  adsOptimization: [AdsOptimization.Conversions],
  bidAmount: [] as number[],
  devices: [{ device: Device.All, browsersAndOS: 'all' }],
  isDynamicCreative: false,
  uploadToInstagram: false,
  conversionsEvent: [] as string[],
  countries: [] as { id: string, value: string }[],
  gender: null,
}

export const targetState = atom<CampaignTarget>({
  key: 'targetState',
  default: initialTargetState as CampaignTarget
});

export const clearToggle = atom({
  key: 'clearToggle',
  default: false
})
