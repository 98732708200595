import { Device, FBInterest, SearchCampaign, SearchProvider } from '../../createCampign.typs';
import { CampaignTarget } from "./components/Target/targetState/target.atom";
import { generateChecksum } from '../../../../../../../core/utils/utils';

export const formatCampaignToServer = (campaignTarget: CampaignTarget, isSearchCampaign: boolean) => {
  return {
    targeting: {
      devicesAndOS: getDevicesAndOsFormat(campaignTarget.devices),
      countries: campaignTarget.countries.map(country => country.id),
      optimizationGoals: campaignTarget.adsOptimization,
      bids: campaignTarget.bidAmount,
      conversionEvents: campaignTarget.conversionsEvent,
      roas: campaignTarget.roas,
      ages: campaignTarget.ages,
      bidStrategies: campaignTarget.bidStrategy,
      interests: getInterestsFormat(campaignTarget.interests),
      audiences: getAudiencesFormat(campaignTarget.customAudiences?.website, campaignTarget.customAudiences?.lookalike),
      browsers: getBrowserFormat(campaignTarget.devices),
    },
    metadata: {
      gender: campaignTarget.gender,
      objective: campaignTarget.objective,
      dynamicCreative: campaignTarget.isDynamicCreative,
      isSearch: isSearchCampaign,
      search: isSearchCampaign ? getSearchCampaignFormat(campaignTarget.searchCampaign as SearchCampaign, campaignTarget.useOriginSearchValues) : undefined,
      customPixel: campaignTarget.customPixel,
      languageSet: campaignTarget.isLanguageSet,
      uploadToInstagram: campaignTarget.uploadToInstagram,
    }
  }
}


const getDevicesAndOsFormat = (devices: { device: string, browsersAndOS: string }[]) => {
  return devices.map(device => {
    if (device.device === Device.Desktop) {
      return {
        device: device.device,
        os: 'all'
      }
    }
    if (device.device === Device.AllAndroid) {
      return {
        device: 'all',
        os: 'android'
      }
    }
    return {
      device: device.device,
      os: device.browsersAndOS,
    }
  })
}

const getBrowserFormat = (devices: { device: string, browsersAndOS: string }[]) => {
  const desktopDevice = devices.filter(device => device.device === Device.Desktop);
  if (desktopDevice.length > 0 && desktopDevice[0].browsersAndOS !== 'all') {
    return [desktopDevice[0].browsersAndOS];
  }
}

const getInterestsFormat = (interests?: FBInterest[]) => {
  return interests?.map(interest => {
    return {
      name: interest.name,
      id: interest.id,
      category: interest.path[0]
    }
  })
}

const getSearchCampaignFormat = (searchCampaign: SearchCampaign, useOriginalSearchValues?: boolean) => {
  switch (searchCampaign.searchProvider) {
    case SearchProvider.Tonic:
      return {
        searchProvider: SearchProvider.Tonic,
        useOriginalSearchValues,
        tonic: {
          campaignId: searchCampaign.tonicCampaign.id.toString()
        }
      }
    case SearchProvider.ExploreAds:
      return {
        searchProvider: SearchProvider.ExploreAds,
        useOriginalSearchValues,
        exploreAds: {
          campaignOffer: searchCampaign.campaignOffer,
          identifier: searchCampaign.identifier
        }
      }
    case SearchProvider.AFS:
      return {
        searchProvider: SearchProvider.AFS,
        useOriginalSearchValues,
        googleDirect: {
          identifier: searchCampaign.identifier
        }
      }
    default:
      return {}
  }
}

const getAudiencesFormat = (websiteAudiences?: { id: string, name: string }[], lookalikeAudiences?: { id: string, name: string }[]) => {
  const websites = websiteAudiences?.map((website) => { return { id: website.id, name: website.name, type: 'website' } }) || [];
  const lookalikes = lookalikeAudiences?.map((lookalike) => { return { id: lookalike.id, name: lookalike.name, type: 'lookalike' } }) || [];
  return [
    ...websites,
    ...lookalikes
  ]
}

export const formatCampaignsDraftsForClient = (drafts: any[], existingCampaigns: any[]) => {
  const existingChecksums = new Set(existingCampaigns.map((item) => item.checksum));
  const lastId = existingCampaigns.length > 0 ? Math.max(...existingCampaigns.map((item) => item.id)) : 0;
  const uniqueDrafts = drafts
    .map((item: any, index: number) => ({
      ...item,
      checksum: generateChecksum(item),
      id: lastId + index + 1,
      searchProvider: item.search?.searchProvider,
      identifier: item.search[item.search?.searchProvider]?.identifier,
      campaignOffer: item.search[item.search?.searchProvider]?.campaignOffer,
    }))
    .filter((item: any) => !existingChecksums.has(item.checksum));
  return [...existingCampaigns, ...uniqueDrafts];
};

